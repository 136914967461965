import { useEffect, useState } from "react";
import "./Menu.scss";
import {
  breakfastItems,
  breakfastSection,
  desertItems,
  desertSection,
  lunchItems,
  lunchSection,
  beverageItem,
  beverageSection,
  alcoholItem,
  alcoholSection,
  coffeeItem,
  coffeeSection,
} from "./data/MenuItems";

const Menu = ({ menuS }) => {
  const [menu, setMenu] = useState([breakfastItems, breakfastSection]);
  const [fadeIn, setFadeIn] = useState(false);
  const [active, setActive] = useState(0);

  useEffect(
    function handleFade() {
      setFadeIn(true);
      setTimeout(() => {
        setFadeIn(false);
      }, 1000);
    },
    [menu]
  );

  return (
    <div className="menu-container" ref={menuS}>
      <div className="menu-header">
        <div className="menu-title">Menu</div>
        <div className="menu-categories">
          <ul className="menu-categories-list">
            <li
              onClick={() => {
                setMenu([breakfastItems, breakfastSection]);
                setActive(0);
              }}
              className={`menu-categories-list-item ${
                active === 0 ? "active" : ""
              }`}
            >
              Breakfast
            </li>
            <li
              className={`menu-categories-list-item ${
                active === 1 ? "active" : ""
              }`}
              onClick={() => {
                setMenu([lunchItems, lunchSection]);
                setActive(1);
              }}
            >
              Lunch
            </li>
            <li
              className={`menu-categories-list-item ${
                active === 2 ? "active" : ""
              }`}
              onClick={() => {
                setMenu([desertItems, desertSection]);
                setActive(2);
              }}
            >
              Hottokeki
            </li>
            {/* <li
              className="menu-categories-list-item"
              onClick={() => {
                setMenu([coffeeItem, coffeeSection]);
              }}
            >
              Coffee
            </li> */}
            <li
              className={`menu-categories-list-item ${
                active === 3 ? "active" : ""
              }`}
              onClick={() => {
                setMenu([beverageItem, beverageSection]);
                setActive(3);
              }}
            >
              Drinks
            </li>
            <li
              className={`menu-categories-list-item ${
                active === 4 ? "active" : ""
              }`}
              onClick={() => {
                setMenu([coffeeItem, coffeeSection]);
                setActive(4);
              }}
            >
              Coffee
            </li>
            <li
              className={`menu-categories-list-item ${
                active === 5 ? "active" : ""
              }`}
              onClick={() => {
                setMenu([alcoholItem, alcoholSection]);
                setActive(5);
              }}
            >
              Alcohol
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`menu-container-menu ${
          menu[1].length <= 1 || menu[0].length < 8 ? "long" : ""
        } ${fadeIn ? "fade" : ""}`}
      >
        <div className="menu-col">
          {menu[1].slice(0, Math.floor(menu[1].length / 2)).map((item, i) => (
            <div className="menu-section" key={item.title}>
              <div className="menu-section-title">{item.title}</div>
              {menu[0].map((e, idx) => {
                return (
                  e.section === item.title && (
                    <div className="menu-item" key={idx + e.title}>
                      <div className="menu-item-heading">{e.title}</div>
                      <p className="menu-item-description">{e.description}</p>
                      <div className="menu-item-price">{e.price}</div>
                      <div className="menu-item-addon">{e.addon}</div>
                    </div>
                  )
                );
              })}
            </div>
          ))}
        </div>
        <div className="menu-col">
          {menu[1].slice(Math.floor(menu[1].length / 2)).map((item, i) => (
            <div className="menu-section" key={item.title}>
              <div className="menu-section-title">{item.title}</div>
              {menu[0].map((e, idx) => {
                return (
                  e.section === item.title && (
                    <div className="menu-item" key={idx + e.title}>
                      <div className="menu-item-heading">{e.title}</div>
                      <p className="menu-item-description">{e.description}</p>
                      <div className="menu-item-price">{e.price}</div>
                      <div className="menu-item-addon">{e.addon}</div>
                    </div>
                  )
                );
              })}
            </div>
          ))}
        </div>

        {/* <div className="menu-col">
          {menu[1].slice(Math.floor(menu[1].length / 2)).map((item, i) => {
            return (
              <div className="menu-section" key={item.title + i}>
                <div className="menu-section-title">{item.title}</div>
                {menu[0].map((e, i) => {
                  return (
                    e.section === item.title && (
                      <div
                        className={`menu-item ${
                          menu[0].length <= 8 ? "long" : ""
                        }`}
                        key={e.title + i}
                      >
                        <div className="menu-item-heading">{e.title}</div>
                        <p className="menu-item-description">{e.description}</p>
                        <div className="menu-item-price">{e.price}</div>
                        <div className="menu-item-addon">{e.addon}</div>
                      </div>
                    )
                  );
                })}
              </div>
            );
          })}
        </div> */}
      </div>
      <div className="floating-img">
        <img
          className="flower-img"
          src={require("../../images/flower3.png")}
          alt=""
        />
        <img
          className="flower-img2"
          src={require("../../images/flower4.png")}
          alt=""
        />
      </div>
    </div>
  );
};
export default Menu;
