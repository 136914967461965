import { useEffect, useState } from "react";
import "./FrontPage.scss";
import { imgCarousel } from "./FrontPageData";

const FrontPage = ({ home }) => {
  const [current, setCurrent] = useState(0);
  const [animate, setAnimate] = useState(true);
  const [timerId, setTimerId] = useState();

  const nextSlide = () => {
    setCurrent(current === imgCarousel.length - 1 ? 0 : current + 1);
  };

  useEffect(() => {
    if (animate) {
      const t = setTimeout(() => {
        nextSlide();
      }, 7000);
      setTimerId(t);
    } else {
      clearTimeout(timerId);
    }
    // eslint-disable-next-line
  }, [current, animate]);

  return (
    <div className="front-page-container" ref={home}>
      {/* <div className="announcement-banner">Grand opening April 28th</div> */}
      <div className="front-page-img-container">
        {imgCarousel.map((img, i) => {
          return (
            <div
              className={i === current ? "slide active" : "slide"}
              key={img.id}
            >
              {
                <img
                  key={img.id}
                  className={
                    i === current + 1 ||
                    (current === imgCarousel.length - 1 && i === 0) ||
                    i === current
                      ? "under"
                      : "none"
                  }
                  src={require("../../images/" + img.url)}
                  alt=""
                />
              }
            </div>
          );
        })}
      </div>
      {/* <img src="" alt="" /> */}
      <div className="logo-name">
        <img
          className="logo-img"
          src={require("../../images/white-logo.png")}
          alt=""
        />
        <div>
          Blossom <span className="spacing"></span>Café
        </div>
      </div>
      <div className="carousel">
        {imgCarousel.map((img, idx) => {
          return (
            <button
              key={img.idx}
              className={
                current === idx ? "carousel-btn highlight" : "carousel-btn"
              }
              onClick={() => {
                setCurrent(idx);
                setAnimate(false);
              }}
            ></button>
          );
        })}
        <button
          className="play-pause-btn"
          onClick={() => {
            setAnimate(!animate);
          }}
        >
          {!animate ? (
            <img src={require("../../images/play-btn.png")} alt="" />
          ) : (
            <img
              className="pause"
              src={require("../../images/pause.png")}
              alt=""
            />
          )}
        </button>
      </div>
    </div>
  );
};
export default FrontPage;
