import Header from "../Components/Header/Header";
import FrontPage from "../Components/FrontPage/FrontPage";
import "./home.scss";
import Menu from "../Components/Menu/Menu";
import About from "../Components/About/About";
import Footer from "../Components/Footer/Footer";
import { useRef } from "react";

const Home = () => {
  const home = useRef(null);
  const menuS = useRef(null);
  const aboutUs = useRef(null);
  const footer = useRef(null);

  const ScrollToSection = elementRef => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="home-container">
      <Header
        home={home}
        menuS={menuS}
        aboutUs={aboutUs}
        footer={footer}
        ScrollToSection={ScrollToSection}
      />
      <FrontPage home={home} />
      <Menu menuS={menuS} />
      <About aboutUs={aboutUs} />
      <Footer footer={footer} />
    </div>
  );
};
export default Home;
