export const imgCarousel = [
  {
    url: "bg-img-20.jpg",
    id: "1454531",
  },
  {
    url: "bg-img-3.jpg",
    id: "431251",
  },
  {
    url: "bg-img-2.jpg",
    id: "14120",
  },
  {
    url: "bg-img-23.jpg",
    id: "34839",
  },
];
