import { useState } from "react";
import "./Header.scss";
import { CgMenuRightAlt } from "react-icons/cg";
import { MdRestaurantMenu } from "react-icons/md";

const Header = ({ home, aboutUs, menuS, ScrollToSection }) => {
  const [open, setOpen] = useState(false);

  function handleScroll() {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }

  const handleMenu = () => {
    open ? setOpen(false) : setOpen(true);
  };
  return (
    <div className="header">
      <div className={`header-container`}>
        <div
          className="logo-container"
          onClick={() => {
            setOpen(false);
            ScrollToSection(home);
          }}
        >
          Blossom Cafe
        </div>
        <div className="list-items-container">
          <nav className="menu-list">
            {/* should be Link style when react router is implemented */}
            <li onClick={() => ScrollToSection(menuS)} className="list-item">
              Menu
            </li>
            <li onClick={() => ScrollToSection(aboutUs)} className="list-item">
              About Us
            </li>
            <li onClick={() => handleScroll()} className="list-item">
              Location & Hours
            </li>
            {/* <li className="list-item">
              {" "}
              <a href="https://resy.com/?date=2024-01-21&seats=2">
                Reservations
              </a>{" "}
            </li> */}
          </nav>
        </div>
        <div className="flower">
          <img
            className="logo-img"
            src={require("../../images/logo1.png")}
            alt=""
          />
        </div>

        <div className="menu-icon" onClick={() => handleMenu()}>
          {open ? (
            <MdRestaurantMenu size={28} className="menu-close" />
          ) : (
            <CgMenuRightAlt size={28} className="menu-open" />
          )}
        </div>
      </div>
      <div className={`mobile-list-items-container ${open ? "open" : "close"}`}>
        <ul className="mobile-menu-list">
          {/* should be Link style when react router is implemented */}
          <li
            onClick={() => {
              handleMenu();
              ScrollToSection(menuS);
            }}
            className="mobile-list-item"
          >
            Menu
          </li>
          <li
            onClick={() => {
              handleMenu();
              ScrollToSection(aboutUs);
            }}
            className="mobile-list-item"
          >
            About Us
          </li>
          <li
            onClick={() => {
              handleMenu();
              handleScroll();
            }}
            className="mobile-list-item"
          >
            Location & Hours
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Header;
