import "./Footer.scss";

const Footer = ({ footer }) => {
  return (
    <div className="footer-main-container" ref={footer}>
      <div className="footer-container">
        <img
          className="black-bg"
          src={require("../../images/black-bg-stroke.png")}
          alt=""
        />
        <div className="footer-logo-container">
          <img src={require("../../images/white-logo.png")} alt="" />
          <p>Blossom Café</p>
        </div>
        <div className="footer-location">
          <div className="location-title">Address</div>
          <div className="location-address">
            <a
              href="https://maps.app.goo.gl/zR8gqPes1m2PsL2x8"
              rel="noreferrer"
              target="_blank"
            >
              6 Wilcox St, Simsbury, 06070
            </a>
          </div>
          <div className="location-title">Contact Us</div>

          <div className="location-phone">
            {" "}
            For Reservations <br />
            Call us: +1 (860) 413-2203
          </div>
          <div className="location-email">
            <a href="mailto:albert@blossom-cafe.com">Email Us</a>
          </div>
        </div>
        <div className="footer-hours">
          <div className="hours">
            <div className="hours-title"> Hours </div>
            <ul className="hours-list">
              <li className="day-time">
                <div className="day">Monday</div>
                <div className="time"> 8am - 3pm</div>
              </li>
              <li className="day-time">
                <div className="day">Tuesday</div>
                <div className="time"> Closed </div>
              </li>
              <li className="day-time">
                <div className="day">Wednesday</div>
                <div className="time"> 8am - 3pm</div>
              </li>
              <li className="day-time">
                <div className="day">Thursday</div>
                <div className="time"> 8am - 3pm</div>
              </li>
              <li className="day-time">
                <div className="day">Friday</div>
                <div className="time"> 8am - 3pm</div>
              </li>
              <li className="day-time">
                <div className="day">Saturday</div>
                <div className="time"> 8am - 3pm</div>
              </li>
              <li className="day-time">
                <div className="day">Sunday</div>
                <div className="time"> 8am - 3pm</div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="flower-container">
          <img
            className="flower1"
            src={require("../../images/flower12.png")}
            alt=""
          />
        </div> */}
      </div>
    </div>
  );
};
export default Footer;
