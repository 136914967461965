import "./About.scss";

const About = ({ aboutUs }) => {
  return (
    <div className="about" ref={aboutUs}>
      <div className="about-container">
        <div className="about-header">About Us</div>
        <div className="about-text">
          <p>
            Welcome to Blossom Café — Connecticut's brunch sanctuary! Our menu
            blends traditional favorites with innovative twists using locally
            sourced, fresh ingredients. From soufflé pancakes to signature
            avocado toast, we cater to diverse tastes. Our inviting space exudes
            a cozy yet modern vibe for leisurely mornings. With a commitment to
            exceptional service and quality, Blossom Café promises a delightful
            brunch experience that keeps you coming back for more.
          </p>
        </div>
        <div className="floating-img-about">
          <img
            className="flower1"
            src={require("../../images/flower14.png")}
            alt=""
          />
          <img
            className="flower2"
            src={require("../../images/flower6.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default About;
